import React from 'react';
// import NavBar from './components/Navbar';
// import './App.css'; // Import the App.css file
// import Hero from './sections/Hero';
// import FeaturesSection from './sections/Features';
// import WorkFlow from './sections/Workflow';
// import Footer from './components/Footer';

function App() {
  return (
    <div >
    {/*  <NavBar />*/}
    {/*  <Hero />*/}
    {/*  <FeaturesSection />*/}
    {/*<WorkFlow />*/}
    {/*   <Footer />*/}
    <img src="https://media.istockphoto.com/id/1011988208/vector/404-error-like-laptop-with-dead-emoji-cartoon-flat-minimal-trend-modern-simple-logo-graphic.jpg?s=1024x1024&w=is&k=20&c=3ZEB3UwsO2MweFMjIJ08wGw5y_sI-aWTdlAIOzekiJk="/>
    </div>
  );
}

export default App;
